<template>
    <div>
        <van-sticky>
            <title-bar2 title="专业详情" />
        </van-sticky>
        <div class="major-box">
            <div class="major-content">
                <h3 class="major-title1">[{{ majorCode }}]{{ majorName }}</h3>
                <div>
                    <span class="major-title2">所属院校</span>
                    <span class="major-text">{{ universityName }}</span>
                </div>
                <div>
                    <span class="major-title2">所属学院</span>
                    <span class="major-text">{{ collegeName }}</span>
                </div>
                <div>
                    <span class="major-title2">招生年份</span>
                    <span class="major-text">{{ majorEnrollmentYear }}</span>
                </div>
                <div>
                    <span class="major-title2">学习方式</span>
                    <span class="major-text">{{ majorLearnway }}</span>
                </div>
                <div>
                    <span class="major-title2">所属门类</span>
                    <span class="major-text">{{ majorBelongcategory }}</span>
                </div>
                <div>
                    <span class="major-title2">所属一级学科</span>
                    <span class="major-text">{{ majorBelongFirstDiscipline }}</span>
                </div>
                <div>
                    <span class="major-title2">统考计划招生人数</span>
                    <span class="major-text">{{ majorPlannedEnrollment }}（不含推免）</span>
                </div>
            </div>
            <div class="major-content">
                <h3 class="major-title1">考试科目</h3>
                <div class="major-title2">研究方向</div>
                <div class="major-text">{{ majorResearchDirection }}</div>
                <div class="major-title2">初试科目</div>
                <span class="major-text">{{ majorFirstSubject }}</span>
                <div class="major-title2">复试科目</div>
                <span class="major-text">{{ majorSecondSubject }}</span>
            </div>
            <div v-if="examDocumentList.length > 0" class="major-content">
                <h3 class="major-title1">历年真题（点击可预览）</h3>
                <div v-for="(docuemntInfo, index) in examDocumentList">
                    <div class="major-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</div>
                    <div class="major-price">全国统一零售价：￥{{ docuemntInfo.DocumentPrice }}</div>
                    <div class="major-line" v-if="index < examDocumentList.length - 1"></div>
                </div>
            </div>
            <div v-if="firstDocumentList.length > 0" class="major-content">
                <h3 class="major-title1">初试资料（点击可预览）</h3>
                <template v-for="(val, key) in firstDocumentGroupList">
                    <template v-if="val.length > 0">
                        <div class="major-title">{{ key }}</div>
                        <div v-for="(docuemntInfo, index) in val">
                            <div class="major-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</div>
                            <div class="major-price">全国统一零售价：￥{{ docuemntInfo.DocumentPrice }}</div>
                            <div class="major-line" v-if="index < val.length - 1"></div>
                        </div>
                    </template>
                </template>
            </div>
            <div v-if="secondDocumentList.length > 0" class="major-content">
                <h3 class="major-title1">复试资料（点击可预览）</h3>
                <div v-for="(docuemntInfo, index) in secondDocumentList">
                    <div class="major-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</div>
                    <div class="major-price">全国统一零售价：￥{{ docuemntInfo.DocumentPrice }}</div>
                    <div class="major-line" v-if="index < secondDocumentList.length - 1"></div>
                </div>
            </div>
            <div v-if="courseInfos.length > 0" class="major-content">
                <h3 class="major-title1">视频网课（点击可试看）</h3>
                <template v-for="(val, key) in courseGroupList">
                    <template v-if="val.length > 0">
                        <div class="major-title">{{ key }}</div>
                        <div v-for="(courseInfo, index) in val">
                            <div class="major-name" @click="onCourseClick(courseInfo)">{{ courseInfo.CourseName }}</div>
                            <div class="major-price">全国统一零售价：￥{{ courseInfo.CourseSellingPrice }}</div>
                            <div class="major-line" v-if="index < val.length - 1"></div>
                        </div>
                    </template>
                </template>
            </div>
            <div v-if="courseCustoms.length > 0" class="major-content">
                <h3 class="major-title1">专属定制服务（点击可试看）</h3>
                <div v-for="(courseInfo, index) in courseCustoms">
                    <div class="major-name" @click="onCourseClick(courseInfo)">{{ courseInfo.CourseName }}</div>
                    <div class="major-price">全国统一零售价：￥{{ courseInfo.CourseSellingPrice }}</div>
                    <div class="major-line" v-if="index < courseCustoms.length - 1"></div>
                </div>
            </div>
            <div v-if="admitInfos.length > 0" class="major-content">
                <h3 class="major-title1">报录数据（点击可查看）</h3>
                <div v-for="(admitInfo, index) in admitInfos">
                    <div class="major-name" @click="onAdmitClick(admitInfo)">{{ admitInfo.AdmitName }}</div>
                    <div class="major-price">数据会员</div>
                    <div class="major-line" v-if="index < admitInfos.length - 1"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";
export default {
    name: "MTModule",
    components: {
        TitleBar2,
    },
    data() {
        return {
            proxyGuid: null,
            universityCode: null,
            collegeCode: null,
            collegeName: null,
            universityName: null,
            majorCode: null,
            majorName: null,
            majorLearnway: null,
            majorEnrollmentYear: null,
            majorBelongcategory: null,
            majorBelongFirstDiscipline: null,
            majorResearchDirection: null,
            majorFirstSubject: null,
            majorSecondSubject: null,
            majorPlannedEnrollment: null,
            examDocumentList: [],
            firstDocumentList: [],
            secondDocumentList: [],
            admitInfos: [],
            courseInfos: [],
            courseCustoms: [],
        };
    },
    computed: {
        schoolImg() {
            if (this.universityCode) {
                return `${Config.school.logo}?SchoolCode=${this.universityCode}`;
            }
        },
        courseGroupList() {
            if (this.courseInfos) {
                var group = {
                    全程班: [],
                    导学课: [],
                    基础班: [],
                    强化班: [],
                    冲刺班: [],
                    其他: [],
                };
                for (var i = 0; i < this.courseInfos.length; i++) {
                    if (this.courseInfos[i].CourseTag == "全程班") {
                        group.全程班.push(this.courseInfos[i]);
                    } else if (this.courseInfos[i].CourseTag == "导学课") {
                        group.导学课.push(this.courseInfos[i]);
                    } else if (this.courseInfos[i].CourseTag == "基础班") {
                        group.基础班.push(this.courseInfos[i]);
                    } else if (this.courseInfos[i].CourseTag == "强化班") {
                        group.强化班.push(this.courseInfos[i]);
                    } else if (this.courseInfos[i].CourseTag == "冲刺班") {
                        group.冲刺班.push(this.courseInfos[i]);
                    } else {
                        group.其他.push(this.courseInfos[i]);
                    }
                }
                return group;
            }
        },
        firstDocumentGroupList() {
            if (this.firstDocumentList) {
                var group = {
                    全套精品: [],
                    基础夯实: [],
                    强化巩固: [],
                    冲刺模拟: [],
                    其他: [],
                };
                var items = this.firstDocumentList.filter(p => p.DocumentType == 0);
                for (var i = 0; i < items.length; i++) {
                    if (items[i].DocumentName.indexOf('【电子书】') >= 0 || items[i].DocumentName.indexOf('【初试】') >= 0) {
                        group.全套精品.push(items[i]);
                    } else if (items[i].DocumentName.indexOf('【基础】') >= 0) {
                        group.基础夯实.push(items[i]);
                    } else if (items[i].DocumentName.indexOf('【强化】') >= 0) {
                        group.强化巩固.push(items[i]);
                    } else if (items[i].DocumentName.indexOf('【冲刺】') >= 0) {
                        group.冲刺模拟.push(items[i]);
                    } else {
                        group.其他.push(items[i]);
                    }
                }
                return group;
            }
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.universityCode = this.$route.params.universityCode;
            this.collegeCode = this.$route.params.collegeCode;
            this.majorCode = this.$route.params.majorCode;
            this.majorLearnway = this.$route.params.majorLearnway;
        },
        //获取是否显示关联课程列表
        getIsShowRelatedCourse() {
            var model = {
                ProxyCode: this.$store.state.proxyCode,
            };
            return this.$axios
                .post(Config.proxy.relatedCourseVisible, model)
                .then((res) => {
                    return res.data.Data.ShowRelatedCourse;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取大学信息
        getUniversityInfo() {
            var model = {
                UniversityCode: this.universityCode,
            };
            this.$axios
                .post(Config.school.schoolInfo, model)
                .then((res) => {
                    this.universityName = res.data.Data.UniversityName;
                    this.addVisitProductRecord();
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取学院信息
        getCollegeInfo() {
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode
            };
            this.$axios
                .post(Config.school.collegeInfo, model)
                .then((res) => {
                    this.collegeName = res.data.Data.CollegeName;
                    this.addVisitProductRecord();
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取专业信息
        getMajorInfo() {
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
                MajorLearningWay: this.majorLearnway,
            };
            this.$axios
                .post(Config.school.majorInfo, model)
                .then((res) => {
                    this.majorName = res.data.Data.MajorName;
                    this.majorEnrollmentYear = res.data.Data.MajorEnrollmentYear;
                    this.majorBelongcategory = res.data.Data.MajorBelongcategory;
                    this.majorBelongFirstDiscipline = res.data.Data.MajorBelongFirstDiscipline;
                    this.majorResearchDirection = res.data.Data.MajorResearchDirection;
                    this.majorFirstSubject = res.data.Data.MajorFirstSubject;
                    this.majorSecondSubject = res.data.Data.MajorSecondSubject;
                    this.majorPlannedEnrollment = res.data.Data.MajorPlannedEnrollment;
                    this.addVisitProductRecord();
                    this.$toast.clear();
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取文档列表
        getDocumentInfos() {
            //真题列表
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
            };
            this.$axios
                .post(Config.school.related.examDocumentList, model)
                .then((res) => {
                    this.examDocumentList = res.data.Data.List;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
            //初试列表
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
            };
            this.$axios
                .post(Config.school.related.firstDocumentList, model)
                .then((res) => {
                    this.firstDocumentList = res.data.Data.List;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
            //复试列表
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
            };
            this.$axios
                .post(Config.school.related.secondDocumentList, model)
                .then((res) => {
                    this.secondDocumentList = res.data.Data.List;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
            };
        },
        //获取课程列表
        getCourseInfos() {
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
            };
            this.$axios
                .post(Config.school.related.courseList, model)
                .then((res) => {
                    this.courseInfos = res.data.Data.List;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取课程定制列表
        getCourseCustoms() {
            var model = {
                PageIndex: 1,
                PageSize: 100,
            };
            this.$axios
                .post(Config.school.related.customList, model)
                .then((res) => {
                    this.courseCustoms = res.data.Data.List;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取报录列表
        getAdmitInfos() {
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
            };
            return this.$axios
                .post(Config.school.related.admitList, model)
                .then((res) => {
                    this.admitInfos = res.data.Data.List;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //添加访问商品记录
        addVisitProductRecord() {
            if (this.universityName && this.collegeName && this.majorName) {
                Manager.addVisitProductRecord(`查院校 ${this.universityName}-${this.collegeName}-${this.majorName}`);
            }
        },
        //添加访问学校记录
        addVisitSchoolRecord() {
            if (this.universityCode && this.collegeCode && this.majorCode) {
                Manager.addVisitSchoolRecord({
                    UniversityCode: this.universityCode,
                    CollegeCode: this.collegeCode,
                    MajorCode: this.majorCode,
                    RecordModule: 6,
                });
            }
        },
        //点击资料
        onDocumentClick(documentInfo) {
            this.$router.push({
                name: 'Document1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    documentGuid: documentInfo.DocumentGuid
                }
            });
        },
        //点击课程
        onCourseClick(courseInfo) {
            this.$router.push({
                name: 'Course1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    courseGuid: courseInfo.CourseGuid
                }
            });
        },
        //点击报录数据
        onAdmitClick(admitInfo) {
            this.$router.push({
                name: "Admit1",
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    universityCode: admitInfo.AdmitUniversityCode,
                    collegeCode: admitInfo.AdmitCollegeCode,
                    majorCode: admitInfo.AdmitMajorCode,
                    majorLearnway: '全日制'
                }
            });
        },
    },
    async mounted() {
        this.getRoutParams();
        this.getUniversityInfo();
        this.getCollegeInfo();
        this.getMajorInfo();
        this.getDocumentInfos();
        this.getAdmitInfos();
        if (await this.getIsShowRelatedCourse()) {
            this.getCourseInfos();
            this.getCourseCustoms();
        }
        this.addVisitSchoolRecord();
    }
};
</script>
<style scoped>
.major-box {
    padding: 0 10px 10px 10px;
}

.major-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.major-title1 {
    font-size: 18px;
    font-weight: 500;
}

.major-title2 {
    display: inline-block;
    width: 150px;
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0;
}

.major-text {
    line-height: 26px;
    color: gray;
    font-size: 14px;
    margin-bottom: 10px;
    white-space: pre-wrap;
}

.major-title {
    font-size: 15px;
    font-weight: 600;
    margin: 10px 0px 10px 0;
}

.major-name {
    color: gray;
    font-size: 14px;
    margin-bottom: 5px;
}

.major-name:hover {
    color: #FE5E03;
}

.major-price {
    width: 100%;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: gray;
    font-size: 14px;
}

.major-line {
    border-bottom: 1px solid #e6e6e6;
    margin: 10px 0px;
}
</style>